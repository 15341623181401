export const MEDIA_RECOMMENDED_ARTICLES = [
  {
    altImage: "",
    description: "Memilih asuransi mobil harus dilakukan dengan cermat, berikut pilihan asuransi mobil All Risk terbaik yang bisa kamu pertimbangkan.",
    image: "https://blog-media.lifepal.co.id/app/uploads/sites/3/2023/11/06070759/asuransi-mobil-all-risk.jpg",
    slug: "asuransi-mobil-all-risk-terbaik",
    title: "15 Asuransi Mobil All Risk Terbaik yang Bisa Jadi Pilihan",
  },
  {
    altImage: "",
    description: "Rate OJK untuk kendaraan menentukan tarif premi asuransi mobil untuk pertanggungan All Risk dan TLO. Simak di sini!",
    image: "https://blog-media.lifepal.co.id/app/uploads/sites/3/2022/01/02213005/plafon-asuransi.jpg",
    slug: "biaya-asuransi-mobil",
    title: "Rate Asuransi Mobil OJK dan Cara Hitung Premi",
  },
  {
    altImage: "",
    description: "Asuransi TLO adalah produk asuransi yang menanggung kerugian total dan kehilangan kendaraan. Ketahui beda Total Loss Only dan All Risk.",
    image: "https://blog-media.lifepal.co.id/app/uploads/sites/3/2022/12/21154012/contoh-polis-asuransi-mobil-toyota.jpg",
    slug: "apa-itu-asuransi-tlo",
    title: "Perbedaan Asuransi Mobil All Risk dan TLO - Intip Manfaatnya!",
  },
  {
    altImage: "",
    description: "Perlukah asuransi kesehatan selain BPJS? Meski sudah ada BPJS, tetap perlu pakai asuransi kesehatan untuk proteksi tambahan.",
    image: "https://blog-media.lifepal.co.id/app/uploads/sites/3/2023/02/06145148/rumah-sakit-lavalette.jpg",
    slug: "kalau-sudah-punya-bpjs-kesehatan-apa-masih-perlu-asuransi-swasta",
    title: "Perlukah Asuransi Kesehatan Selain BPJS? Ini Jawabannya!",
  }
]