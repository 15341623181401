import React from 'react';


const ErrorConclusion = () => {
  return (
    <div className="error-conclusion">
      <i className="lifepal-warning"></i>
      <div>
        Lengkapi informasi yang diwajibkan
      </div>
    </div>
  )
}

export default ErrorConclusion;
