import React from 'react';
import dynamic from 'next/dynamic';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import './HeroLanding.scss';

const Avatar = dynamic(() => import('@lifepal/lifepal-design-system').then(design => design.Avatar), {
  ssr: false
});

const HeroLanding = ({ insuranceItems, hasWEBPSupport, loginMode, additionalInsuranceItems }) => {
  const loginModeInsuranceItems = loginMode ? [...insuranceItems, ...additionalInsuranceItems] : insuranceItems;
  
  return (
    <div className={classNames('HeroLanding', loginMode && 'login__mode')} >
      {
        !loginMode && (
          <>
            <h1 className="heading-sm-b hero__meta">Cari Asuransi Terbaik di Marketplace No. 1 Indonesia</h1>
            <div className="sub__info--wrapper">
              <div className="heading-sm-r sub__info">
                <span>
                  Diskon Terbaik
                </span>
              </div>
              <div className="heading-sm-r sub__info">
                <span>100+ Pilihan</span>
              </div>
              <div className="heading-sm-r sub__info">
                <span>Bantuan Klaim</span>
              </div>
            </div>
          </>
        )
      }
      <div className={classNames('product__card--hero', loginMode && 'login__mode')}>
        {
          loginModeInsuranceItems.map((item) => (
          <div
            key={item.id}
            rel="noopener noreferrer"
            onClick={item.onClick}
            className={classNames('HeroLanding--card-wrapper', loginMode && 'login__mode')}
          >
            <div className="HeroLanding--card">
              <div 
                className="product__image" 
                id={`product__image--${item.id}`} 
                style={{
                  backgroundImage: `url(${hasWEBPSupport
                      ? '/static/images/products/hero-banner.webp'
                      : '/static/images/products/hero-banner.png'
                    })`,
                  backgroundPosition: item.backgroundPosition,
                }}
              />
              <p>{item.title}</p>
            </div>
          </div>
        ))}
      </div>
      {
        !loginMode && (
          <div className="search__flow--filter">
            {
              additionalInsuranceItems.map((item, index) => (
                <a href={item.url} key={index} rel="noopener noreferrer" className="product__search--landing">
                  <div
                    className="landing__icon"
                    id={`landing__icon--${item.id}`} 
                    style={{
                      backgroundImage: `url(${hasWEBPSupport
                          ? '/static/images/products/hero-banner.webp'
                          : '/static/images/products/hero-banner.png'
                        })`
                    }}
                  />
                  <span className="heading-2xs-r text-capitalize">{item.title}</span>
                </a>
              ))
            }
          </div>
        )
      }
    </div>
  );
}

HeroLanding.propTypes = {
  insuranceItems: PropTypes.array,
};

export default HeroLanding;
