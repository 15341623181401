import React from "react";
import * as Yup from "yup";
import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import { Input } from "../../../Form";
import { Dropdown } from "../../../Form/";
import { isPhoneNumberValid } from "../../../../lib/phoneNumber";
import CallNotification from "../../../modals/CallNotificaion";
import ErrorConclusion from "../../../LeadForm/ErrorConclusion";

import "./LandingLeadForm.scss";

const LandingLeadForm = ({
  touched,
  errors,
  loading,
  isPopup,
  closePopup,
  isWhatsapp,
}) => {
  const insuranceTypeOption = [
    { label: "Kesehatan", value: "health" },
    { label: "Jiwa", value: "life" },
    { label: "Mobil", value: "auto" },
  ];

  return (
    <>
      {loading && <CallNotification />}

      <div className={isPopup ? "landingLeadForm__popup--background" : ""}>
        <Form
          className={
            isPopup ? "landingLeadForm__popup--box" : "landingLeadForm"
          }
        >
          {isPopup && (
            <img src="/static/images/big_discount.png" alt="Big discount" loading="lazy" />
          )}
          <div className="landingLeadForm__head">
            <p
              className={
                isPopup
                  ? "landingLeadForm__popup__head--title"
                  : "landingLeadForm__head--title"
              }
            >
              {isPopup
                ? "Penawaran Promo Asuransi"
                : "Bandingkan Pilihan Asuransi Terbaik dan Termurah di Lifepal"}
            </p>
            <div
              className={
                isPopup
                  ? "landingLeadForm__popup__head--subtitle"
                  : "landingLeadForm__head--subtitle"
              }
            >
              <div>
                Dapatkan <span className="label-discount">DISKON 25%</span>
              </div>
              {!isPopup && <div>&nbsp;Daftar sekarang untuk penawaran terbatas</div>}
              {isWhatsapp && (
                <div className="whatsapp-subtitle">
                  Silakan isi data Anda dibawah ini untuk berbicara dengan
                  spesialis kami
                </div>
              )}
            </div>
          </div>
          <div
            className={
              isPopup ? "landingLeadForm__popup__body" : "landingLeadForm__body"
            }
          >
            <div
              className={
                isPopup
                  ? "landingLeadForm__popup__body--fieldWrap"
                  : "landingLeadForm__body--fieldWrap"
              }
            >
              <div className="landingLeadForm__body--field">
                <label htmlFor="fullName">Nama Lengkap</label>
                <Field name="fullName">
                  {({ field }) => (
                    <Input
                      {...field}
                      id="fullName"
                      type="text"
                      size="md"
                      error={touched.fullName && errors.fullName}
                    />
                  )}
                </Field>
              </div>
              {!isWhatsapp && (
                <div className="landingLeadForm__body--field">
                  <label htmlFor="phoneNumber">Nomor Telepon Anda</label>
                  <Field name="phoneNumber">
                    {({ field }) => (
                      <Input
                        {...field}
                        id="phoneNumber"
                        phone
                        size="md"
                        error={touched.phoneNumber && errors.phoneNumber}
                      />
                    )}
                  </Field>
                </div>
              )}
              {isWhatsapp && (
                <div className="landingLeadForm__body--field">
                  <label htmlFor="email">Email</label>
                  <Field name="email">
                    {({ field }) => (
                      <Input
                        {...field}
                        id="email"
                        email
                        size="md"
                        error={touched.email && errors.email}
                      />
                    )}
                  </Field>
                </div>
              )}
              <div className="landingLeadForm__body--field">
                <label>{isWhatsapp ? "Jenis Asuransi" : "Tipe"}</label>
                <Field name="insuranceType">
                  {({ field }) => (
                    <Dropdown
                      useRs={true}
                      {...field}
                      name="insuranceType"
                      placeholder="Pilih asuransi"
                      error={touched.insuranceType && errors.insuranceType}
                      options={insuranceTypeOption}
                    />
                  )}
                </Field>
              </div>
            </div>
            {(Object.keys(errors).length > 0 && Object.keys(touched).length > 0) && <ErrorConclusion />}
            {isWhatsapp ? (
              <button
                type="submit"
                className="landingLeadForm__body--submitBtnWA"
              >
                <i className="lifepal-whatsapp" />
                <span> {loading ? "Loading..." : "Dapatkan Promo"}</span>
              </button>
            ) : (
              <button
                type="submit"
                className="landingLeadForm__body--submitBtn"
              >
                {loading ? "Loading..." : "Dapatkan Sekarang"}
              </button>
            )}

            {isPopup && (
              <button
                type="submit"
                className={classNames(
                  "landingLeadForm__popup--closeBtn",
                  isWhatsapp
                    ? "gtm_whatsapppopup_cancelclick"
                    : "gtm_closepopupleadform"
                )}
                onClick={() => closePopup()}
              >
                Lewati
              </button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

const EnhancedForm = withFormik({
  mapPropsToValues: (props) => ({
    fullName: "",
    phoneNumber: "",
    insuranceType: "",
    email: "",
    isWhatsapp: props.isWhatsapp,
  }),
  validationSchema: Yup.object().shape({
    fullName: Yup.string().required("Wajib Diisi"),
    insuranceType: Yup.string().required("Wajib Diisi"),
    email: Yup.string().when("isWhatsapp", {
      is: true,
      then: Yup.string().required("Wajib Diisi"),
      otherwise: Yup.string(),
    }),
    phoneNumber: Yup.string()
      .test("phoneNumber", "Nomor Telepon tidak valid.", (value) => {
        if (value) {
          return isPhoneNumberValid(value, false);
        }
        return true;
      })
      .when("isWhatsapp", {
        is: false,
        then: Yup.string().required("Wajib Diisi"),
        otherwise: Yup.string(),
      }),
  }),
  handleSubmit: (values, { props }) => props.handleSubmit(values),
});

export default EnhancedForm(LandingLeadForm);
