import "./CallNotification.module.scss";

export default function CallNotification() {
  return (
    <div className="call-notif__backdrop">
      <div className="box">
        <img
          className="loading-img"
          src="/static/images/Loading.gif"
          alt="Loading"
        />
        <p className="title">
          Kami akan menghubungi Anda untuk membantu menemukan pilihan terbaik
        </p>
        <p>Sambil menunggu, silahkan lihat pilihan polis</p>
      </div>
    </div>
  );
}
