/* eslint-disable react/prop-types */
import React from 'react';

import './GoogleReview.scss';

const GoogleReview = () => (
  <h2 className="google__review--title">Mengapa Beli Asuransi di Lifepal?</h2>
);

export default GoogleReview;
